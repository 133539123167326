<template>
  <div class="game_contain wb-100 hb-100">
    <div class="game_head center wb-100 h-100 relative">
      <div class="back fz-30 t-w pointer" @click="handleBack">
        <i class="el-icon-arrow-left fz-40"></i>
        <span class="ml-17">返回</span>
      </div>
      <div class="fz-30 t-w">{{ title }}</div>
    </div>
    <div class="livebox">
        <video
          class="video"
          :src="video_url"
          preload="auto"
          width="100%"
          height="100%"
          playsinline
          autoplay
          muted
          webkit-playsinline
          controls
          disablePictureInPicture
          controlsList="nodownload noplaybackrat noplaybackrate"
        ></video>
    </div>
  </div>
</template>
<script>
import { imageUrl } from "../common/common";
export default {
  data() {
    return {
      imageUrl,
      width: "",
      height: "",
      title: "直播回放",
      video_url: ''
    };
  },
  created() {
    this.getQuery();
  },
  methods: {
    handleBack() {
      this.$router.go(-1);
    },
    getQuery() {
      const { title } = this.$route.query;

      this.title = title;
      this.video_url = window.localStorage.getItem('video_url')
    },
  },
};
</script>

<style lang="scss" scoped>
.back {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.game_contain {
  background-color: #f5f6fa;
  .game_head {
    background-color: #212526;
  }
}

.livebox {
  width: 100%;
  height: calc(100% - 100px);
  background-color: #000;
}
</style>
